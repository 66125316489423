import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    CssBaseline,
    Grid,
    Link,
    Paper,
    Typography,
    Rating, alpha, Stack
} from "@mui/material";
import CoolAppBar from "../components/AppBar";
import Drawer from "../components/Drawer";
import React from "react";
import {styled} from "@mui/material/styles";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import GitHubIcon from '@mui/icons-material/GitHub';
import SchoolIcon from '@mui/icons-material/School';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import TerminalIcon from '@mui/icons-material/Terminal';
import "../styles/AboutMe.css";

const AboutMe = (props: any) => {
    const CoolPaper = styled(Paper)(({theme}) => ({
        backgroundColor: theme.background.default2,
        color: 'white',
        boxShadow: 'none'
    }));

    // eslint-disable-next-line no-empty-pattern
    const CoolAvatar = styled(Avatar)(({}) => ({
        width: '200px',
        height: '200px',
    }));

    const CoolCard = styled(Card)(({theme}) => ({
        backgroundColor: theme.background.paper,
        color: 'white',
        '& .MuiTypography-root': {
            color: theme.text.primary,
        },
    }));
    const CoolRating = styled(Rating)(({theme}) => ({
        '& .MuiRating-iconFilled': {
            color: theme.secondary.main,
        },
        '& .MuiRating-iconEmpty': {
            color: theme.text.secondary,
        }
    }));

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <CoolAppBar drawerWidth={props.dw}
                        handleToggle={props.handleToggle}
                        site="About Me"/>
            <Box
                component="nav"
                sx={{width: {sm: props.dw}, flexShrink: {sm: 0}}}
            >
                <Drawer drawerWidth={props.dw}
                        mobileOpen={props.mobileOpen}
                        handleToggle={props.handleToggle}
                        close={props.close}

                />
            </Box>
            <Box
                component="main"
                sx={{mt: 10, flexGrow: 1, p: 3, width: {sm: `calc(100% - ${props.dw}px)`}}}
            >
                <CoolPaper>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <CoolAvatar src="avatar.jpg"/>
                        </Grid>
                    </Grid>
                    <Box sx={{width: '100%', mt: 2}}>
                        <Stack direction="column" alignItems="center">
                            <CoolPaper>
                                <Typography sx={{fontWeight: 'bold', fontSize: "20px"}}>
                                    Silvio Ciavarrella
                                </Typography>
                            </CoolPaper>
                            <CoolPaper>
                                <Typography sx={{fontSize: "20px"}}>
                                    Software Developer
                                </Typography>
                            </CoolPaper>
                        </Stack>

                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Stack>
                            <Timeline>
                                <TimelineItem>
                                    <TimelineOppositeContent>
                                        <Typography sx={{mt: '11px'}}>
                                            12/2022 - heute
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot sx={{bgcolor: (theme) => theme.secondary.main}}>
                                            <TerminalIcon/>
                                        </TimelineDot>
                                        <TimelineConnector/>
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        Junior Software Developer <br/>
                                        <Typography color="deepskyblue">Cognizant Mobility GmbH</Typography>
                                    </TimelineContent>

                                </TimelineItem>

                                <TimelineItem>
                                    <TimelineOppositeContent sx={{mt: '18px'}}>
                                        08/2018 - 12/2022
                                    </TimelineOppositeContent>

                                    <TimelineSeparator>
                                        <TimelineConnector/>
                                        <TimelineDot sx={{bgcolor: (theme) => alpha(theme.secondary.main, 0.3)}}>
                                            <SchoolIcon/>
                                        </TimelineDot>
                                        <TimelineConnector/>
                                    </TimelineSeparator>

                                    <TimelineContent sx={{mt: '6px'}}>
                                        B.Sc. Wirtschaftsinformatik <br/>
                                        <Typography color="deepskyblue">Technische Hochschule Ulm</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineOppositeContent sx={{mt: '19px'}}>
                                        07/2017 - 06/2018
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector/>
                                        <TimelineDot sx={{
                                            bgcolor: (theme) => alpha(theme.secondary.main, 0.3),

                                        }}>
                                            <BusinessCenterIcon/>
                                        </TimelineDot>
                                        <TimelineConnector/>
                                    </TimelineSeparator>
                                    <TimelineContent sx={{mt: '6px'}}>
                                        Fachhochschulreife <br/>
                                        <Typography color="deepskyblue">Kaufmännische Schule Ehingen</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineOppositeContent sx={{mt: '24px'}}>
                                        07/2014 - 06/2016
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector/>
                                        <TimelineDot sx={{bgcolor: (theme) => alpha(theme.secondary.main, 0.3)}}>
                                            <BusinessCenterIcon/>
                                        </TimelineDot>
                                    </TimelineSeparator>
                                    <TimelineContent sx={{mt: '10px'}}>
                                        Fachschulreife <br/>
                                        <Typography color="deepskyblue" component="span">
                                            Kaufmännische Schule Ehingen
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Stack>
                    </Box>
                </CoolPaper>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <CoolCard>
                            <CardHeader
                                sx={{textAlign: 'center'}}
                                title="IT-Skills"
                                subheader="Kenntnisstand"
                            />
                            <Grid container justifyContent="center">
                                <Grid item sm={4} md={3} lg={2} xl={1}>
                                    <CardContent>
                                        <Typography>Java</Typography>
                                        <CoolRating value={5} readOnly/>
                                        <Typography>MySQL</Typography>
                                        <CoolRating value={4} readOnly/>
                                        <Typography>JS, CSS, HTML</Typography>
                                        <CoolRating value={3} readOnly/>
                                        <Typography>React</Typography>
                                        <CoolRating value={3} readOnly/>
                                        <Typography>Python</Typography>
                                        <CoolRating value={3} readOnly/>
                                    </CardContent>
                                </Grid>
                                <Grid item sm={4} md={3} lg={2} xl={1}>
                                    <CardContent>
                                        <Typography>JavaFX</Typography>
                                        <CoolRating value={5} readOnly/>
                                        <Typography>REST API</Typography>
                                        <CoolRating value={4} readOnly/>
                                        <Typography>Spring</Typography>
                                        <CoolRating value={3} readOnly/>
                                        <Typography>Deeplearning4j</Typography>
                                        <CoolRating value={3} readOnly/>
                                    </CardContent>
                                </Grid>
                                <Grid item sm={4} md={3} lg={2} xl={1}>
                                    <CardContent>
                                        <Typography>Intellij</Typography>
                                        <CoolRating value={5} readOnly/>
                                        <Typography>Linux (Ubuntu)</Typography>
                                        <CoolRating value={4} readOnly/>
                                        <Typography>Docker</Typography>
                                        <CoolRating value={4} readOnly/>
                                        <Typography>Kubernetes</Typography>
                                        <CoolRating value={3} readOnly/>
                                        <Typography>Jenkins</Typography>
                                        <CoolRating value={2} readOnly/>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </CoolCard>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <CoolCard>
                            <CardHeader sx={{textAlign: 'center'}}
                                        title="Socials"/>
                            <CardContent>
                                <Grid spacing={2}
                                      container justifyContent="center" alignItems="center">
                                    <Grid item>
                                        <Link href="https://www.github.com/ciavarrella">
                                            <GitHubIcon fontSize="large" sx={{
                                                transition: 'transform 450ms',
                                                '&:hover': {
                                                    transform: 'translateY(-10px)'
                                                }
                                            }}/>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="https://www.freelancejunior.de/freelancer/silvio-1">
                                            <img src={"freelance-junior-gmb-h-logo-xl.png"}
                                                 className="junico-logo"
                                                 width={35}
                                                 height="auto"
                                                 alt="Freelancer Junior"
                                            />
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="https://www.linkedin.com/in/silvio-ciavarrella-816169214/">
                                            <LinkedInIcon fontSize="large" sx={{
                                                transition: 'transform 450ms',
                                                '&:hover': {
                                                    transform: 'translateY(-10px)'
                                                }
                                            }}/>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </CoolCard>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

}

export default AboutMe;