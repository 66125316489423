import {
    Box,
    Card,
    CardContent,
    CardHeader, Chip, Container, CssBaseline,
    Grid, Stack,
    Typography
} from "@mui/material";
import CoolAppBar from "../components/AppBar";
import Drawer from "../components/Drawer";
import React from "react";
import {styled} from '@mui/material/styles';
import CoolAccordionWrap from "../components/Accordion";
import CoolTimeline from "../components/Timeline";
import AppsIcon from '@mui/icons-material/Apps';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import LanguageIcon from '@mui/icons-material/Language';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import WebIcon from '@mui/icons-material/Web';
import MemoryIcon from '@mui/icons-material/Memory';
import HttpIcon from '@mui/icons-material/Http';

const Projects = (props: any) => {
    const CustomCard = styled(Card)(({theme}) => ({
        backgroundColor: theme.background.paper,
        color: theme.text.primary,
        '& .MuiTypography-root': {
            color: theme.text.primary,
        }
    }));
    styled(Container)(({theme}) => ({
        backgroundColor: theme.background.default2
    }));


    const CoolChip = styled(Chip)(({theme}) => ({
        backgroundColor: theme.info.main,
        color: theme.text.primary
    }))

    const CoolCardHeader = styled(CardHeader)(() => ({
        textAlign: 'center'
    }))

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <CoolAppBar drawerWidth={props.dw}
                        handleToggle={props.handleToggle}
                        site="Projektliste"/>
            <Box
                component="nav"
                sx={{width: {sm: props.dw}, flexShrink: {sm: 0}}}
            >
                <Drawer drawerWidth={props.dw}
                        mobileOpen={props.mobileOpen}
                        handleToggle={props.handleToggle}
                        close={props.close}
                />
            </Box>
            <Box
                component="main"
                sx={{mt: 10, flexGrow: 1, p: 3, width: {sm: `calc(100% - ${props.dw}px)`}}}
            >
                <Grid container
                      spacing={2}>
                    <Grid item xl={6} lg={6} sm={12} xs={10}>
                        <CustomCard>
                            <CoolCardHeader
                                title="Project Spring Microservices"
                                subheader="Praxissemester"
                            />
                            <CardContent>
                                <CoolTimeline
                                    start="01.09.2021"
                                    end="28.02.2022"
                                />
                                <Typography color="white" fontWeight="bold">
                                    Auftraggeber
                                </Typography>
                                <Typography sx={{mb: 2}}>
                                    Müller Holding GmbH & Co. KG
                                </Typography>
                                <CoolAccordionWrap
                                    icon={<AppsIcon/>}
                                    primary="Entwicklung von Microservices"
                                    text="Bei der Enwicklung wurde das Spring Cloud Framework verwendet,
                                    wobei die Module Spring Cloud Stream mit RabbitMQ im Einsatz waren. Zwei
                                    Microservices wurden entwickelt die jeweils mit dieser als Schnittstelle Kommunizieren
                                    können."/>
                                <CoolAccordionWrap
                                    icon={<CloudQueueIcon/>}
                                    primary="Backend Applikation Spring Cloud Config"
                                    text="Der Cloud Config Backend ist eine Anwendung zur Konfiguration der Microservices. Mithilfe
                                    von Spring Cloud Bus, die RabbitMQ verwendet, können alle Applikationen in der Laufzeit aktualisiert werden"/>
                                <CoolAccordionWrap
                                    icon={<LanguageIcon/>}
                                    primary="React Website"
                                    text="Eine Web Anwendung für den Cloud Config Server um Konfigurationen Benutzerfreundlich durchzuführen"/>
                                <Typography sx={{mb: 1, mt: 2}} align="center">
                                    Technologien
                                </Typography>
                                <Stack direction="row" spacing={2}>
                                    <CoolChip size="small" label="JDK 11"/>
                                    <CoolChip size="small" label="Spring"/>
                                    <CoolChip size="small" label="Docker"/>
                                    <CoolChip size="small" label="Kubernetes"/>
                                </Stack>
                            </CardContent>
                        </CustomCard>
                    </Grid>
                    <Grid item xl={6} lg={6} sm={12} xs={10}>
                        <CustomCard>
                            <CoolCardHeader
                                title="Bachelorthesis"
                                subheader="Entwurf einer KI - Anomaly Detection in Logdateien zur Erkennung von Cyberangriffen"
                            />
                            <CardContent>
                                <CoolTimeline
                                    start="10.04.2021"
                                    end="01.09.2022"
                                />
                                <Typography color="white" fontWeight="bold">
                                    Auftraggeber
                                </Typography>
                                <Typography sx={{mb: 2}}>
                                    Technische Hochschule Ulm
                                </Typography>
                                <CoolAccordionWrap
                                    icon={<AppsIcon/>}
                                    primary="Bachelorthesis"
                                    text="Im Rahmen dieser Arbeit wurde eine Anomaly Detection in Logdateien zur Erkennung
von Cyberangriffen sowie interner Auffälligkeiten durchgeführt. Dadurch soll das Finden unentdeckter Hackerattacken ermöglicht werden. Des Weiteren wurde in der Arbeit die folgende Forschungsfragen beantwortet: Welche Arten von
Anomalien befinden sich in den Logdateien und wie groß ist der Anteil von Cyberangriffen im Vergleich zu internen Auffälligkeiten? Diese Problemstellung wurde mit dem
Teams Data Science Process bearbeitet, indem drei Algorithmen implementiert wurden. Dabei wurden die One-Class Support Vector Machine, der Isolation Forest und
der Histogram-based Outlier Score verwendet, um die Anomalien zu analysieren. Die
Ergebnisse dieser Arbeit zeigen, dass keine Hackerangriffe als Anomalien erkannt werden konnten. Zum großen Teil wurden nur der normale Berufsalltag und Sicherheitsbedenkliche Anomalien erkannt. Cyberangriffe konnten deshalb nicht erkannt werden,
weil diese den normalen Berufsalltag ähnlich sind."/>

                                <Typography sx={{mb: 1, mt: 2}} align="center">
                                    Technologien
                                </Typography>
                                <Stack direction="row" spacing={2}>
                                    <CoolChip size="small" label="Python"/>
                                    <CoolChip size="small" label="PyOD"/>
                                    <CoolChip size="small" label="Graylog"/>
                                    <CoolChip size="small" label="Elasticsearch"/>
                                </Stack>
                            </CardContent>
                        </CustomCard>
                    </Grid>
                    <Grid item xl={6} lg={6} sm={12} xs={10}>
                        <CustomCard>
                            <CoolCardHeader
                                title="Standort Analyse"
                                subheader="Wirtschaftsinformatik-Projekt"
                            />
                            <CardContent>
                                <CoolTimeline
                                    start="03.2021"
                                    end="07.2021"/>
                                <Stack direction="row" spacing={5}>
                                    <Box sx={{backgroundColor: (theme) => theme.background.paper}}>
                                        <Typography color="white" fontWeight="bold">
                                            Auftraggeber
                                        </Typography>
                                        <Typography sx={{mb: 2}}>
                                            Müller Holding GmbH & Co. KG
                                        </Typography>
                                    </Box>
                                    <Box sx={{backgroundColor: (theme) => theme.background.paper}}>
                                        <Typography color="white" fontWeight="bold">
                                            Scrum (Team)
                                        </Typography>
                                        <Typography sx={{mb: 2}}>
                                            4 Personen
                                        </Typography>
                                    </Box>
                                </Stack>

                                <CoolAccordionWrap
                                    icon={<SavedSearchIcon/>}
                                    primary="Elasticsearch"
                                    text="Entwicklung der Schnittstelle zu Elasticsearch"/>
                                <CoolAccordionWrap
                                    icon={<WebIcon/>}
                                    primary="Entwicklung der GUI"
                                    text="Die Grafische Oberfläche wurde mit JavaFX entwicklet"/>
                                <CoolAccordionWrap
                                    icon={<MemoryIcon/>}
                                    primary="Entwicklung Neuronales Netzwerk"
                                    text="Ein Neuronales Netzwerk mit Deeplearning4j wurde Entwickelt um eine Standortanalyse zu tätigen"/>
                                <Typography sx={{mb: 1, mt: 2}} align="center">
                                    Technologien
                                </Typography>
                                <Stack direction="row" spacing={2}>
                                    <CoolChip size="small" label="JDK 11"/>
                                    <CoolChip size="small" label="JavaFX"/>
                                    <CoolChip size="small" label="Elasticsearch"/>
                                    <CoolChip size="small" label="Deeplearning4j"/>
                                    <CoolChip size="small" label="JUnit"/>
                                </Stack>
                            </CardContent>
                        </CustomCard>
                    </Grid>
                    <Grid item xl={6} lg={6} sm={12} xs={10}>
                        <CustomCard>
                            <CoolCardHeader
                                title="Applikation zur Aktienkursvorhersage"
                                subheader="Privates Projekt"
                            />
                            <CardContent>
                                <CoolAccordionWrap
                                    icon={<MemoryIcon/>}
                                    primary="Entwicklung Neuronales Netzwerk"
                                    text="Für die Aktienkursvorhersage wird ein Rekurrentes Neuronales Netzwerk verwendet"/>
                                <CoolAccordionWrap
                                    icon={<HttpIcon/>}
                                    primary="Schnittstelle zur Abfrage der Kursdaten"
                                    text="Die Kursdaten werden per HTTP Request von Alphavantage4j abgerufen"/>
                                <CoolAccordionWrap
                                    icon={<WebIcon/>}
                                    primary="Entwicklung der GUI"
                                    text="Die Benutzeroberfläche wurde mit JavaFX erstellt"/>
                                <Typography sx={{mb: 1, mt: 2}} align="center">
                                    Technologien
                                </Typography>
                                <Stack direction="row" spacing={2}>
                                    <CoolChip size="small" label="JDK 8"/>
                                    <CoolChip size="small" label="JavaFX"/>
                                    <CoolChip size="small" label="Deeplearning4j"/>
                                    <CoolChip size="small" label="Alphavantage4j"/>
                                </Stack>
                            </CardContent>
                        </CustomCard>
                    </Grid>
                </Grid>

            </Box>
        </Box>
    );
}

export default Projects;