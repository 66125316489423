import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import React from "react";
import {styled} from "@mui/material/styles";

const CoolAccordionWrap = (props: any) => {

    const CoolAccordion = styled(Accordion)(({theme}) => ({
        backgroundColor: theme.background.default,
        borderBottom: `1px solid ${theme.divider}`
    }))

    const CoolAvatar = styled(Avatar)(({theme}) => ({
        backgroundColor: theme.info.main
    }))

    return (
        <CoolAccordion>
            <AccordionSummary
                expandIcon={<ExpandMore sx={{color: 'white'}}/>}
            >
                <ListItem>
                    <ListItemAvatar>
                        <CoolAvatar>
                            {props.icon}
                        </CoolAvatar>
                    </ListItemAvatar>
                    <ListItemText primary={props.primary}/>
                </ListItem>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {props.text}
                </Typography>
            </AccordionDetails>
        </CoolAccordion>
    )
}

export default CoolAccordionWrap;