import React, {useState} from 'react';
import {
    Divider,
    ListItemIcon,
    ListItemText, MenuItem, MenuList,
    Drawer as MuiDrawer,
    SvgIcon, SvgIconProps, Toolbar, Box
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";

interface List {
    name: string,
    url: string
}


const Drawer = (props: any) => {
    const { window } = props;

    const data = [
        {
            name: "About Me",
            url: "/",
        }, {
            name: "Projects",
            url: "/projects",
        }
    ]
    const [list] = useState<List[]>(data)


    function HomeIcon(props: SvgIconProps) {
        return (
            <SvgIcon {...props}>
                <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
            </SvgIcon>
        );
    }

    const CoolMuiDrawer = styled(MuiDrawer)(({theme}) => ({
        backgroundColor: theme.background.default,
        "& .MuiTypography-root": {
            color: theme.primary.main,
        },
        '& .MuiDrawer-paper': {
            backgroundColor: theme.background.default,
            boxSizing: 'border-box',
            width: props.drawerWidth,
        },
        '& .MuiSvgIcon-root': {
            color: theme.primary.main,
        }
    }));

    const drawer = (
        <div>
            <Toolbar variant="dense"/>
            <Divider sx={{
                mt: '7px',
                mb: '5px',
                bgcolor: (theme) => theme.divider
            }}/>
            <MenuList
                onClick={props.close}
            >
                {Object.values(list).map((elem: List, i: number) =>
                    <MenuItem key={i} component={Link} to={elem.url}
                              sx={{
                                  '&:hover': {
                                      bgcolor: (theme) => theme.secondary.main
                                  }
                              }}>
                        <ListItemIcon >
                            {i === 0 ? <HomeIcon/> : i === 1 ? <PersonIcon/> : <AccountTreeIcon/>}
                        </ListItemIcon>
                        <ListItemText primary={elem.name}/>
                    </MenuItem>
                )}
            </MenuList>
        </div>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }}>
            <CoolMuiDrawer
                container={container}
                anchor="left"
                variant="temporary"
                open={props.mobileOpen}
                onClose={props.handleToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
                }}
            >
                {drawer}
            </CoolMuiDrawer>
            <CoolMuiDrawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
                }}
                open
            >
                {drawer}
            </CoolMuiDrawer>
        </Box>
    )
}

export default Drawer;