import {AppBar, Avatar, Box, IconButton, Toolbar, Tooltip, Typography}
    from "@mui/material";
import React from "react";
import {styled} from "@mui/material/styles";
import {Link} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';

const CoolAppBar = (props: any) => {
    const CoolAppBar = styled(AppBar)(({theme}) => ({
        backgroundColor: theme.primary.main,
        "& .MuiTypography-root": {
            color: theme.secondary.main,
        }
    }));

    return (
        <CoolAppBar position="fixed" sx={{
            width: { sm: `calc(100% - ${props.drawerWidth}px)` },
            ml: { sm: `${props.drawerWidth}px` },
        }}>
            <Toolbar variant="dense">
                <IconButton
                    color="primary"
                    edge="start"
                    onClick={props.handleToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    color="inherit"
                    sx={{flexGrow: 1}}>
                    {props.site}
                </Typography>
                <Box>
                    <Tooltip title="Open Profile">
                        <IconButton component={Link} to="/">
                            <Avatar src="avatar.jpg"/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
        </CoolAppBar>);
}
export default CoolAppBar;