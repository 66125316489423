import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import AboutMe from "./pages/AboutMe";
import Projects from "./pages/Projects";
import ReactDOM from 'react-dom';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";

declare module '@mui/material/styles' {
    interface Theme {
        primary: {
            main: string,
        },
        secondary: {
            main: string,
        },
        background: {
            default: string,
            default2: string,
            paper: string,
        },
        text: {
            primary: string,
            secondary: string,
            disabled: string,
            hint: string,
        },
        info: {
            main: string,
        },
        divider: string;
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        primary?: {
            main?: string,
        },
        secondary?: {
            main?: string,
        },
        background?: {
            default?: string,
            default2?: string,
            paper?: string,
        },
        text?: {
            primary?: string,
            secondary?: string,
            disabled?: string,
            hint?: string,
        },
        info?: {
            main?: string,
        },
        divider?: string;
    }
}

export const theme = createTheme({
    primary: {
        main: '#fdfdfd',
    },
    secondary: {
        main: '#3e5fe1',
    },
    background: {
        default: '#222222',
        default2: '#282828',
        paper: '#313131',
    },
    text: {
        primary: '#ffffff',
        secondary: '#838383',
        disabled: 'rgba(168,167,167,0.38)',
        hint: 'rgba(197,193,193,0.38)',
    },
    info: {
        main: '#1070d9',
    },
    divider: '#1969e0'
})

const App = () => {
    const drawerWidth = 170;
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleToggle = () => {
        setMobileOpen(!mobileOpen);
    }
    const closeDrawer = () => {
        setMobileOpen(false);
    }

    useEffect(() => {
        document.body.style.backgroundColor = theme.background.default2;
    }, [])

    return (
        <Routes>
            <Route path="/" element={
                <AboutMe dw={drawerWidth}
                         mobileOpen={mobileOpen}
                         handleToggle={handleToggle}
                         close={closeDrawer}
                />
            }/>
            <Route path="/projects" element={
                <Projects dw={drawerWidth}
                          mobileOpen={mobileOpen}
                          handleToggle={handleToggle}
                          close={closeDrawer}
                />
            }/>
        </Routes>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App/>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
